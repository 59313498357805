import * as $ from 'jquery'
import 'bootstrap'
import 'slick-carousel'
import * as AOS from 'aos'

AOS.init()
setTimeout(function () {
	AOS.refresh()
}, 500)

$('.slider').slick({
	slidesToShow: 6,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	arrows: false,
	dots: false,
	pauseOnHover: false,
	responsive: [
		{ breakpoint: 1023, settings: { slidesToShow: 4 } },
		{ breakpoint: 768, settings: { slidesToShow: 3 } },
		{ breakpoint: 520, settings: { slidesToShow: 2 } },
	],
})
